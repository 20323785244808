:where([data-sonner-toaster][dir='ltr'] button[data-close-button]) {
  --toast-close-button-start: auto;
  --toast-close-button-end: 0;
  --toast-close-button-transform: translate(35%, -35%);
  background-color: #eaeaea !important;
}

:where([data-sonner-toaster][dir='rtl'] button[data-close-button]) {
  --toast-close-button-start: 0;
  --toast-close-button-end: auto;
  --toast-close-button-transform: translate(-35%, -35%);
  background-color: #c9cacd !important;
}