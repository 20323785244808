@media print {
  //@page {
  //  size: landscape;
  //}

  body * {
    visibility: hidden;
  }

  .print-content {
    position: fixed;
    left: 0;
    top: 0;

    & * {
      visibility: visible;
    }
  }

  html, body {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
