.honeycomb-bg {
  top: -6px;
  left: 25px;
}
.honeycomb-item {
  height: 28px; /* adjust to control the size  */
  aspect-ratio: 1/cos(30deg);
  clip-path: polygon(50% -50%,100% 50%,50% 150%,0 50%);
  background: white;
  position: absolute;
}

.honeycomb-item:nth-child(1) {
  left: -32px;
  top: 0;
  opacity: 0.34;
}

.honeycomb-item:nth-child(2) {
  left: -6px;
  top: -15px;
  opacity: 0.47;
}

.honeycomb-item:nth-child(3) {
  left: -6px;
  top: 15px;
  opacity: 0.23;
}

.honeycomb-item:nth-child(4) {
  left: 20px;
  top: 0;
  opacity: 0.23;
}

.honeycomb-item:nth-child(5) {
  left: 20px;
  top: 30px;
  opacity: 0;
}

.honeycomb-item:nth-child(6) {
  left: -32px;
  top: 30px;
  opacity: 0.47;
}

.honeycomb-item:nth-child(7) {
  left: -6px;
  top: 45px;
  opacity: 0;
}

.honeycomb-item:nth-child(8) {
  left: -32px;
  top: 60px;
  opacity: 0.28;
}

.honeycomb-item:nth-child(9) {
  left: -6px;
  top: 75px;
  opacity: 0.28;
}