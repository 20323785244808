.resource {

  &-overview {
    .fc {
      &-toolbar {
        &-title {
          font-size: .875em;
          @media screen and (min-width: 640px) { font-size: 1.25em; }
          @media screen and (min-width: 1024px) { font-size: 1.75em; }
        }
      }
    }
  }

  &-calendar {
    .fc {
      &-toolbar {
        @media screen and (max-width: 768px) { flex-direction: column; gap: 10px; }
      }
    }
  }

}
