// Form Variables
@import "../../../../assets/scss/Variables";

@mixin missing-fields-border {
  border: 2px solid #FE5353;
  background: rgba(#FE5353, .02)
}

// Form Page Root
#reusableform {
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #ECEEF1 100%) 0 0 no-repeat padding-box;

  /*******************/
  /***** GENERAL *****/
  /*******************/

  @keyframes expandWidth {
    0% { width: 0; }
    100% { width: 100%; }
  }

  @keyframes expandFromLeft {
    0% { width: 0; }
    100% { width: 6px; }
  }

  input {
    font-size: 14px;
    padding: 10px 7px;

    @media screen and (min-width: 900px) {
      font-size: inherit;
      padding: 10px 14px;
    }
  }

  & > div {
    position: relative;

    &.container { align-items: normal }

    .wrapper {
      &.custom-auto-mx {
        @media screen and (min-width: 1600px) { margin: 0 auto }
      }
    }

    .content{
      position: relative;
      & > :before {
        content: "";
        height: 1px;
        width: 100%;
        background: $dark;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: .1;
      }
    }
    h2 {
      line-height: 3rem;

      span {
        position: relative;
        width: fit-content;
        display: flex;

        &:after {
          display: block;
          content: "";
          height: 10px;
          width: 100%;
          max-width: 140px;
          background: $blue-light-title;
          position: absolute;
          bottom: -10px;
          left: 0;
          clip-path: polygon(0 4%, 100% 40%, 100% 100%, 1% 100%);

          @media screen and (min-width: 1200px) {
            max-width: 180px;
            bottom: -12px;
          }
        }
      }
    }
  }

  // Buttons
  .button {
    justify-content: center;
    gap: 8px;
    border-radius: 50px;
    border: 1px solid #DCDCDC;
    padding: 6px 14px;
    font-size: 14px;
    background-color: #F0F0F0;
    font-weight: 400;
    min-width: 86px;
    opacity: 1;

    svg { font-size: 20px; }

    &.back { color: #272727; }
    &.next {
      color: #ffffff;
      background-color:#3380FF;
      &:hover { background-color: #3383BB}

      &[disabled] { opacity: 0.3; transition: opacity 1s ease; }
    }

    &.add { color: #272727; }
    &.remove {
      color: #ffffff;
      background-color:#3380FF;
      &:hover { background-color: #3383BB}

      &[disabled] { opacity: 0.3; transition: opacity 1s ease; }
    }
    
    @media screen and (min-width: 1200px) {
      & {
          padding: 12px 30px;
          font-size: 16px;
          min-width: 136px;
        }
    }
  }

  // ScrollTop Button
  .scroll-top { align-self: center; margin: 0; }

  .form-choices-stepper {
    .MuiStepIcon-text { font-size: .8rem; }
  }


  // Form Root
  .reusableform {

    // Stepper
    .stepper {
      .MuiStepLabel {

        &-labelContainer {
          height: 100%;

          & > span { height: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
          }
        }

      }

      // Swiper
      .swiper-wrapper {
        &-parent-class {
          .swiper-wrapper {
            justify-content: center;

            .swiper-slide.style-override {

              @media screen and (min-width: 1200px) {
                width: 330px!important;
              }

            }
          }
        }
      }
      .swiper-button-disabled { display: none; }
    }

      // Mui Box Structure
    &-box-structure {
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      //min-height: 100%;
      flex-grow: 1;
      margin: 0 0 60px;
      width: 100%;
    }

    // Card Item
    .card-item {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid #f4f4f5;
      width: 100%;
      padding: 10px 4px 24px;
      min-height: 100px;
      position: relative;
      color: $grey-light-border;
      margin: 0;
      font-weight: 600;

      @media screen and (min-width: 1200px) {
        max-width: 220px;
      }

      @media screen and (min-width: 1200px) {
        & { padding: 4px; }
      }

      .radio-item { position: absolute; top: 0; right: 2px; }

      // Locked/Disabled Item
      &.locked {
        color: $grey-light-text;
        background-color: rgba($grey-light-text, .08);

        button {
          margin: 0 0 0 auto;
          position: absolute;
          right: 0;
          top: 2px;
          width: 100%;
          height: 100%;
          color: $grey-light-text;
          z-index: 20;

          &:hover { background: none }

          .radio-item { top: -4px; right: -2px; }
        }
      }

      span.MuiTypography-root { max-width: 76%; }
      .default-tag {
        font-size: 10px;
        width: fit-content;
        color: white;
        padding: 1px 4px;
        position: absolute;
        bottom: 3px;
        right: 3px;
      }
    }

    // Mui List /  ListItem
    ul {
      & > li {
        padding: 0;

        // No border-bottom for every closed li.displaying EXCEPT the last one
        &.displaying:not(:nth-last-child(1 of .displaying)) {
          .accordion-summary[aria-expanded=false] { border-bottom: none; }
        }

        &.hidden { display: none; }


        // Accordion
        .accordion {
          width: 100%;
          position: relative;

          // Accordion Summary
          &-summary {
            padding: 10px 14px 10px 20px;
            border-width: 1px 0 1px 0;
            border-color: rgba(51, 131, 187, .5);
            border-style: solid;
            border-radius : unset;
            min-height: 70px;

            & > div { margin: 0; align-items: center; }

            &[aria-expanded=true] {
              font-weight: 700;
            }

            .form-title {
              a {
                text-decoration: underline;
                &:hover { text-decoration: none; }
              }
            }

            @media screen and (min-width: 900px) { padding: 10px 35px; }
          }

          // Accordion Expand Icon
          .MuiAccordionSummary-expandIconWrapper {
            color: rgba(45, 126, 245, .75);
            font-size: 1.6rem;
          }

          &.Mui-expanded {
            &:after {
              content: '';
              display: block;
              position: absolute;
              width: auto;
              height: calc(100% - 60px);
              background-color: rgba($blue-light-title, 1);
              top: 0;
              left: 0;
              animation: expandFromLeft .2s ease forwards;
            }
          }
        }


      }
    }

    // Multiple lines Labels
    .flex-grid-input {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-end;
    }

    // Selects
    .select {

      .Mui-focused {
        & > .select-icon { transform: rotate(90deg); }
      }

      .MuiSvgIcon-root { display: none; }

      .MuiChip {
        &-root {
          height: auto;

          @media screen and (min-width: 900px) {
            height: 32px;
          }
        }
        &-label {
          white-space: normal;

          @media screen and (min-width: 900px) {
            white-space: nowrap;
          }
        }
      }
    }

    // CKEditdor TextBox
    div[role=textbox], .ck-editor__editable_inline {
      min-height: 240px;

      a {
        color: #1b3af2;
        text-decoration: underline;
        cursor: pointer;

        &:hover { text-decoration: none; }
      }
    }

    // EMAIL FORMAT ERROR
    .no-value {
      & > div > input + fieldset { @include missing-fields-border; }
    }

    // ERRORS - Unfilled Inputs
    &.missing-fields-focus {

      article { padding-top: 20px }

      // TextFields
      input.no-value + fieldset { @include missing-fields-border; }

      // Date Pickers
      input.no-value + div + fieldset {
        @include missing-fields-border;
        border-radius: 5px;
      }

      // TextAreas
      div.no-value {
        div[role=textbox] { @include missing-fields-border; }
      }

      // Selects
      .select:has(.no-value) {
        border: none;

        & > fieldset { @include missing-fields-border; }
      }

      // Checkbox
      .checkbox:has(.no-value) { color: #FE5353; }

      // File Upload
      .no-value {
        &.upload-file { @include missing-fields-border; }
      }

      // Resources & ResourceTypes
      .no-value {
        & > .resource-card { @include missing-fields-border; }
      }
    }

  } // .reusableform end



  // Form Confirmation page
  .success {
    &-box {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 700px;
      gap: 6%;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 10px;
      padding: 10%;
      box-shadow: 0 4px 14px 4px rgba(0, 0, 0, 0.06);
      margin: auto;
      text-align: center;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        border: 1px solid rgba(39, 39, 39, 0.08);
        border-radius: 7px;
        width: 98%;
        height: 98%;
        top: 1%;
      }

      @media screen and (min-width: 600px) {
        padding: 2%;

        &:before { width: 98%; height: 96%; top: 2%; }
      }
    }

    &-title {
      margin: 10% 0;

      span:after { background: #5bb543; animation: expandWidth 1s ease; }

      @media screen and (min-width: 600px) { margin: 2% 0 6%; }
    }

    &-text {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      gap: 6px;
      position: relative;
      z-index: 3;


      p { margin: 3% 0; }
      h3 { color: inherit }

      @media screen and (min-width: 900px) {
        max-width: 70%;
      }
    }

    &-link {
      background-color: rgba(91, 181, 67, 0.1);
      width: 100%;
      border-radius: 7px;
      padding: 3% 0;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-decoration: underline;
        position: relative;
        z-index: 1;
        margin: 3% 0;

        span.italic { margin: 0 2% 0 0; }

        &:hover { text-decoration: none; }
      }

    }
  }



  // Invalid URL
  .invalid-url {
    &-title {
      margin: 20px 0 40px;

      span:after { background: #FE5353; animation: expandWidth 1s ease; animation-delay: .16s; }

      @media screen and (min-width: 900px) { margin: 0 0 6%; }
    }

    &-text {
      a { text-decoration: underline }
    }
  }
}


// SELECT DROPDOWNS (Classic & Preload Services)
.MuiListSubheader-root {
  &.preload-subheader {
      background: rgba(118, 137, 250, 1);
      color: #FFFFFF;
      font-weight: 600;
      padding: 0 16px;
  }
}

.MuiButtonBase-root {
  &.select-subheader {
    background: rgba(118, 137, 250, 1);
    color: #FFFFFF;
    font-weight: 600;
    padding: 6px 16px;
  }

  &.select-items, &.preload-items {
    font-size: .9rem;
    padding: 10px 16px 10px 32px;
    position: relative;
  }
}



// Form Right Sidebar
.form-drawer {

  // Mobile
  &-mobile {
    .content {
      display: flex;
      gap: 10px 0;

      & > div { width: 100%; }
      h2 { margin: 10px 0; font-size: 1.125rem }
      h3 { margin: 10px 0; font-size: 1rem  }
    }

    .MuiPaper-root { width: 100%; }
  }

  // General
  .content {
    display: flex;

    & > div { width: 100%; }
    .logo { min-height: 40px; margin: 0 0 14px; }
    .name {
      min-height: 20px;
      margin: 0 0 25px;
      font-family: "'Lora', serif", serif;
      font-size: 20px;
      font-weight: 700;
    }
    .image {
      min-height: 240px;
      margin: 0 0 30px;

      img { border-radius: 10px; }
    }
    .description {
      margin: 0 0 15px;

      h2 { margin: 10px 0; font-size: 1.125rem }
      h3 { margin: 10px 0; font-size: 1rem  }

      p {
        font-size: 14px;
        opacity: .95;
        color: $grey-light-text;

        br:first-of-type { display: none; }
        strong { display: block; font-size: 16px; text-decoration: underline; font-weight: 400; margin: 10px 0 20px; }

        &:last-of-type { margin-bottom: 50px;  }
      }
    }
  }

  &-button {
    &.MuiButtonBase-root {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      background-color: #CEE6F8;
      margin: 0;
      border-radius: unset;
      position: fixed;
      z-index: 1;
      bottom: 0;
      color: #3383BB;
      &:hover { transform: none; background-color: rgb(235, 245, 252);  }
    }
    .icon { font-size: 22px; margin: 0 0 0 15px; }
  }
}