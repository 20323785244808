#resource-infos {
  #reusableform.resource-infos {
    background: none;
  }
  #reusableform.resource-infos .container {
    justify-content: center;
  }
  #reusableform.resource-infos .wrapper {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  #reusableform.resource-infos .stepper {
    padding-top: 0;

    @media screen and (max-width: 1200px) {
      margin: 0;
    }
  }
}